// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-404-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/pages/404.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-404-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-contact-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/pages/contact.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-contact-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-offline-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/pages/offline.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-pages-offline-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-article-template-amp-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/templates/article.template.amp.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-article-template-amp-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-article-template-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/templates/article.template.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-article-template-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-articles-template-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/templates/articles.template.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-articles-template-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-author-template-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/templates/author.template.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-author-template-tsx" */),
  "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-tag-template-tsx": () => import("./../node_modules/@draftbox-co/gatsby-ghost-novela-theme/src/templates/tag.template.tsx" /* webpackChunkName: "component---node-modules-draftbox-co-gatsby-ghost-novela-theme-src-templates-tag-template-tsx" */),
  "component---src-draftbox-co-gatsby-ghost-novela-theme-templates-page-template-tsx": () => import("./../src/@draftbox-co/gatsby-ghost-novela-theme/templates/page.template.tsx" /* webpackChunkName: "component---src-draftbox-co-gatsby-ghost-novela-theme-templates-page-template-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-live-tsx": () => import("./../src/pages/live.tsx" /* webpackChunkName: "component---src-pages-live-tsx" */),
  "component---src-pages-state-guide-tsx": () => import("./../src/pages/state-guide.tsx" /* webpackChunkName: "component---src-pages-state-guide-tsx" */),
  "component---src-templates-state-data-tsx": () => import("./../src/templates/State.Data.tsx" /* webpackChunkName: "component---src-templates-state-data-tsx" */)
}

